import { Container, Typography, Box } from "@mui/material";

const Regulations = () => {
  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Regulamin
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Definicje
        </Typography>
        <Typography paragraph>
          ○ Usługa: Generowanie planów lekcji za pośrednictwem aplikacji.
          <br />
          ○ Użytkownik: Osoba fizyczna lub prawna korzystająca z Usługi.
          <br />○ Operator: Właściciel aplikacji.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Postanowienia ogólne
        </Typography>
        <Typography paragraph>
          Usługa jest dostępna w dwóch wersjach: darmowej i płatnej.
          <br />
          Użytkownik zobowiązuje się do korzystania z Usługi zgodnie z
          regulaminem i obowiązującym prawem.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. Rejestracja i Konto Użytkownika
        </Typography>
        <Typography paragraph>
          Rejestracja wymaga podania prawdziwych danych.
          <br />
          Użytkownik zobowiązany jest do ochrony danych logowania.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Zakres Usługi
        </Typography>
        <Typography paragraph>
          ○ Wersja darmowa:
          <br />
          ■ Użytkownik ma dostęp do podstawowych funkcji aplikacji, które
          pozwalają na:
          <br />
          ■ Konfigurację szkoły.
          <br />
          ■ Dodawanie wymagań dotyczących planu lekcji z ograniczoną ilością
          personalizacji.
          <br />
          ■ Generowanie planu lekcji.
          <br />
          ■ Eksportowanie planu do PDF.
          <br />
          ○ Wersja płatna:
          <br />
          ■ Użytkownik ma dostęp do wszystkich funkcji aplikacji, w tym:
          <br />
          ■ Zaawansowane opcje konfiguracji szkoły.
          <br />
          ■ Szczegółowe i zaawansowane wymagania dotyczące planu lekcji.
          <br />
          ■ Generowanie planu lekcji z pełnymi opcjami personalizacji.
          <br />■ Eksportowanie planu do PDF oraz Excel.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Ograniczenia
        </Typography>
        <Typography paragraph>
          ○ Darmowa wersja aplikacji może być ograniczona pod względem liczby
          generowanych planów lekcji oraz dostępnych opcji konfiguracyjnych.
          <br />
          Operator zastrzega sobie prawo do wprowadzenia limitów użytkowania dla
          użytkowników wersji darmowej, w celu zapewnienia stabilności i jakości
          usługi dla wszystkich użytkowników.
          <br />○ Aplikacja może nie generować idealnych planów lekcyjnych w
          przypadku sprzecznych wymagań lub zbyt rygorystycznych ograniczeń.
          Zachęcamy do stopniowego zwiększania wymagań, aby uzyskać jak
          najlepsze wyniki.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Wersje i aktualizacje
        </Typography>
        <Typography paragraph>
          Operator może wprowadzać nowe wersje i aktualizacje aplikacji, które
          mogą zmieniać zakres dostępnych funkcji zarówno w wersji darmowej, jak
          i płatnej.
          <br />
          Użytkownik zostanie powiadomiony o takich zmianach z odpowiednim
          wyprzedzeniem.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Płatności
        </Typography>
        <Typography paragraph>
          ○ Cennik dostępny na stronie Operatora.
          <br />
          ○ Opłaty nie podlegają zwrotowi po wygenerowaniu płatnych planów.
          <br />
          ○ Okres trwania subskrypcji wynosi 30 dni.
          <br />
          ○ Odstąpienie od umowy:
          <br />■ Użytkownik ma prawo odstąpić od umowy w ciągu 14 dni od jej
          zawarcia bez podania przyczyny, zgodnie z ustawą z dnia 30 maja 2014
          r. o prawach konsumenta, art. 27. Aby skorzystać z prawa odstąpienia,
          użytkownik musi poinformować Operatora o swojej decyzji w formie
          jednoznacznego oświadczenia (np. pismo wysłane pocztą, faksem lub
          pocztą elektroniczną). Opłata nie podlega zwrotowi po wygenerowaniu
          planu lekcyjnego.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Polityka prywatności
        </Typography>
        <Typography paragraph>
          Dane osobowe przetwarzane są zgodnie z Polityką Prywatności dostępną
          na stronie Operatora.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Odpowiedzialność Operatora
        </Typography>
        <Typography paragraph>
          Operator nie odpowiada za szkody wynikłe z korzystania z Usługi.
          Operator zastrzega sobie prawo do wprowadzania zmian w Usłudze.
        </Typography>

        <Typography variant="h6" gutterBottom>
          10. Reklamacje
        </Typography>
        <Typography paragraph>
          ○ Użytkownik może zgłaszać reklamacje dotyczące funkcjonowania Usługi
          na adres e-mail: kontakt@easyschedule.pl.
          <br />○ Reklamacje będą rozpatrywane w terminie 14 dni od daty ich
          otrzymania.
        </Typography>

        <Typography variant="h6" gutterBottom>
          11. Rozwiązanie Umowy
        </Typography>
        <Typography paragraph>
          Użytkownik może w każdej chwili zrezygnować z korzystania z Usługi.
          Operator może zawiesić konto w przypadku naruszenia regulaminu.
        </Typography>

        <Typography variant="h6" gutterBottom>
          12. Postanowienia końcowe
        </Typography>
        <Typography paragraph>
          Regulamin wchodzi w życie z dniem publikacji na stronie Operatora.
          Wszelkie spory rozstrzygane będą przez sąd właściwy dla siedziby
          Operatora.
        </Typography>

        <Typography variant="h6" gutterBottom>
          13. Kontakt
        </Typography>
        <Typography paragraph>
          Wszelkie pytania i uwagi należy kierować na adres e-mail Operatora:
          kontakt@easyschedule.pl.
        </Typography>
      </Box>
    </Container>
  );
};

export default Regulations;
