import axios from "axios";
import ErrorHandler from "./ErrorHandler.js";
import { bsonToJs } from "./bsonToJs.js";

class DataService {
  URL = process.env.REACT_APP_API_URL;
  AT;

  axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("AT"),
    },
    // withCredentials: true,
  };

  setToken() {
    this.AT = localStorage.getItem("AT");
    this.axiosConfig.headers.Authorization = this.AT;
  }

  async purchase(data) {
    try {
      this.setToken();
      const response = await axios.post(
        `${this.URL}/createPayment`,
        data,
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status
      );
      return errorHandler.checkErrorStatus();
    }
  }

  async postRequirement(data) {
    try {
      this.setToken();
      const response = await axios.post(
        `${this.URL}/requirement`,
        data,
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status
      );
      return errorHandler.checkErrorStatus();
    }
  };

  async deleteRequirement(id) {
    try {
      this.setToken();
      const response = await axios.delete(
        `${this.URL}/requirement/${id}`,
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status
      );
      return errorHandler.checkErrorStatus();
    }
  };

  async getSchoolPlanConfig(id) {
    try {
      this.setToken();
      const parsedId = bsonToJs(id);
      const response = await axios.get(
        `${this.URL}/schoolPlanConfig/${parsedId}`,
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status
      );
      return errorHandler.checkErrorStatus();
    }
  }

  async postData(data, endpoint) {
    try {
      this.setToken();
      const response = await axios.post(
        `${this.URL}/${endpoint}`,
        data,
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status
      );
      return errorHandler.checkErrorStatus();
    }
  }

  async editData(data, endpoint, id) {
    try {
      this.setToken();
      const parsedId = bsonToJs(id);
      const response = await axios.put(
        `${this.URL}/${endpoint}/${parsedId}`,
        data,
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status
      );
      return errorHandler.checkErrorStatus();
    }
  }

  async deleteData(endpoint, id) {
    try {
      this.setToken();
      const parsedId = bsonToJs(id);
      const response = await axios.delete(
        `${this.URL}/${endpoint}/${parsedId}`,
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status
      );
      return errorHandler.checkErrorStatus();
    }
  }

  async login(endpoint, data) {
    try {
      const response = await axios.post(
        `${this.URL}/${endpoint}`,
        data,
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status,
        error.code
      );
      return errorHandler.checkErrorStatus();
    }
  }

  async register(endpoint, data) {
    try {
      const response = await axios.post(
        `${this.URL}/${endpoint}`,
        data,
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status
      );
      return errorHandler.checkErrorStatus();
    }
  }

  async getUserData(endpoint) {
    try {
      this.setToken();
      const response = await axios.get(
        `${this.URL}/${endpoint}`,
        this.axiosConfig
      );
      return response;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status
      );
      return errorHandler.checkErrorStatus();
    }
  }

  async getSchoolPlans() {
    try {
      this.setToken();
      const response = await axios.get(
        `${this.URL}/schoolPlans`,
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status
      );
      return errorHandler.checkErrorStatus();
    }
  }

  async getRequirements(schoolPlanId) {
    try {
      this.setToken();
      const parsedId = bsonToJs(schoolPlanId);
      const response = await axios.get(
        `${this.URL}/requirements/${parsedId}`,
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status
      );
      return errorHandler.checkErrorStatus();
    }
  }

  async copyClassrooms(fromLessonId, toLessonId) {
    try {
      this.setToken();
      const response = await axios.post(
        `${this.URL}/copy/classrooms`,
        { from_lesson: fromLessonId, to_lesson: toLessonId },
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status
      );
      return errorHandler.checkErrorStatus();
    }
  }

  async copyLessons(lessonsToCopy, toClass) {
    try {
      this.setToken();
      const response = await axios.post(
        `${this.URL}/copy/lessons`,
        {
          lessons_to_copy: lessonsToCopy.map((lesson) => lesson._id),
          to_class: toClass,
        },
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status
      );
      return errorHandler.checkErrorStatus();
    }
  }

  async generateSchedule(schoolPlanId) {
    try {
      this.setToken();
      const parsedId = bsonToJs(schoolPlanId);
      console.log(parsedId)
      const response = await axios.get(
        `${this.URL}/generatePlan/${parsedId}`,
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      console.log(error)
      console.error("Detailed Error:", {
        message: error.message,
        responseStatus: error.response
          ? error.response.status
          : "No response status",
        responseData: error.response ? error.response.data : "No response data",
      });
      const errorHandler = new ErrorHandler(
        error.message,
        error.response ? error.response.status : 500
      );
      return errorHandler.checkErrorStatus();
    }
  }

  async getCurrentJobs() {
    try {
      this.setToken();
      const response = await axios.get(
        `${this.URL}/currentJobs`,
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status
      );
      return errorHandler.checkErrorStatus();
    }
  }

  async activateUser(token) {
    try {
      this.setToken();
      const response = await axios.post(
        `${this.URL}/activate`,
        { token },
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status
      );
      return errorHandler.checkErrorStatus();
    }
  }

  async resendToken(email) {
    try {
      this.setToken();
      const response = await axios.post(
        `${this.URL}/regenerateActivationLink`,
        { email },
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status
      );
      return errorHandler.checkErrorStatus();
    }
  }

  async recoveryPassword(email) {
    try {
      this.setToken();
      const response = await axios.post(
        `${this.URL}/recoveryPassword`,
        { email },
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status
      );
      return errorHandler.checkErrorStatus();
    }
  }

  async resetPassword(password, token) {
    try {
      this.setToken();
      const response = await axios.post(
        `${this.URL}/changePassword`,
        { password, token },
        this.axiosConfig
      );
      return response.data;
    } catch (error) {
      const errorHandler = new ErrorHandler(
        error.message,
        error.response.status
      );
      return errorHandler.checkErrorStatus();
    }
  }

  async isLogged() {
    try {
      this.setToken();
      await axios.get(`${this.URL}/isLogged`, this.axiosConfig);
      return true;
    } catch(error) {
      return false;
    }
  }
}

export default new DataService();
