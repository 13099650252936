import React from "react";
import styles from "./Navigation.module.scss";
import LoginIcon from "@mui/icons-material/Login";
import { Button } from "@mui/material";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AuthStore from "../../mobx/AuthStore";
import { observer } from "mobx-react";
import LoadingBar from "../LoadingBar/LoadingBar";

import DataService from "../../services/DataService";

import SchoolInfoStore from "../../mobx/SchoolInfoStore";

import Logo from "../../icons/LogoES.svg";
import { toJS } from "mobx";

const Navigation = observer(() => {
  const navigate = useNavigate();
  const { isUserLogged, loading, user } = AuthStore;

  const schoolConfig = toJS(user?.schoolPlanConfigs.name);

  const schoolName = schoolConfig;

  return (
    <nav className={styles.navigation}>
      <Container>
        <div className={styles.desktop}>
          <div className={styles.logo}>
            <img src={Logo} alt="logo Easy schedule" />
          </div>
          <div className={styles.nav}>
            <a href="#description">Opis</a>
            <a href="#functionalities">Funkcjonalności</a>
            <a href="#capabilities">Możliwości</a>

            <a href="#benefits">Korzyści</a>
            <a href="#payment">Metody Płatności</a>
          </div>
          <div className={styles.profile}>
            {loading ? (
              <LoadingBar />
            ) : !isUserLogged ? (
              <Button
                startIcon={<LoginIcon />}
                variant="contained"
                color="primary"
                onClick={() => navigate("/login")}
              >
                Logowanie
              </Button>
            ) : (
              <div className={styles.loggedIn}>
                <p>ZALOGOWANY NA KONTO:</p>
                <Button onClick={() => navigate("/profile")}>
                  {schoolName}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Container>
    </nav>
  );
});

export default Navigation;
