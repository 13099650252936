import React, { useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
} from "@mui/material";
import PaymentStore from "../../mobx/PaymentStore";
import { paymentFormErrorHandler, validateNipCode, validatePostalCode } from "./paymentModule.config";
import { toJS } from "mobx";

const PaymentForm = ({ handleNext }) => {
  const { paymentUserInfo: user } = toJS(PaymentStore);
  const paymentUserInfoDefaultState = user || {
    invoice_needed: false,
    is_company: false,
    company_name: "",
    tax_code: "",
    name: "Jan",
    surname: "Kowalski",
    street: "",
    street_number: "",
    flat_number: "",
    post_code: "",
    city: "",
  }
  const [paymentUserInfo, setPaymentUserInfo] = useState(paymentUserInfoDefaultState);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setPaymentUserInfo({
      ...paymentUserInfo,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = () => {
    const isCorrectForm = paymentFormErrorHandler(paymentUserInfo);

    if (!validatePostalCode(paymentUserInfo.post_code)) {
      return;
    }

    if (paymentUserInfo.is_company && !validateNipCode(paymentUserInfo.tax_code)) {
      return;
    }

    if (!isCorrectForm) {
      return;
    }

    const data = paymentUserInfo;

    if(!paymentUserInfo.is_company) {
      data.company_name = null;
      data.tax_code = null;
    };

    PaymentStore.setPaymentUserInfo(paymentUserInfo);
    handleNext();
  };

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Imię"
          name="name"
          value={paymentUserInfo.name}
          onChange={handleChange}
          required
          error={!paymentUserInfo.name}
          helperText={!paymentUserInfo.name && "Imię jest wymagane"}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Nazwisko"
          name="surname"
          value={paymentUserInfo.surname}
          onChange={handleChange}
          required
          error={!paymentUserInfo.surname}
          helperText={!paymentUserInfo.surname && "Nazwisko jest wymagane"}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={paymentUserInfo.invoice_needed}
              onChange={handleChange}
              name="invoice_needed"
            />
          }
          label="Faktura na osobę prywatną"
        />
      </Grid>
        <>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Ulica"
              name="street"
              value={paymentUserInfo.street}
              onChange={handleChange}
              required
              error={!paymentUserInfo.street}
              helperText={!paymentUserInfo.street && "Ulica jest wymagana"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Numer domu"
              name="street_number"
              value={paymentUserInfo.street_number}
              onChange={handleChange}
              required
              error={!paymentUserInfo.street_number}
              helperText={!paymentUserInfo.street_number && "Numer domu jest wymagany"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Numer mieszkania"
              name="flat_number"
              value={paymentUserInfo.flat_number}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Kod pocztowy"
              name="post_code"
              value={paymentUserInfo.post_code}
              onChange={handleChange}
              required
              error={!validatePostalCode(paymentUserInfo.post_code)}
              helperText={
                !validatePostalCode(paymentUserInfo.post_code) && 
                "Błędny kod pocztowy: XX-XXX"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Miasto"
              name="city"
              value={paymentUserInfo.city}
              onChange={handleChange}
              required
              error={!paymentUserInfo.city}
              helperText={!paymentUserInfo.city && "Miasto jest wymagane"}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={paymentUserInfo.is_company}
                  onChange={handleChange}
                  name="is_company"
                />
              }
              label="Faktura na firmę"
            />
          </Grid>
          {paymentUserInfo.is_company && (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nazwa firmy"
                  name="company_name"
                  value={paymentUserInfo.company_name}
                  onChange={handleChange}
                  required
                  error={!paymentUserInfo.company_name}
                  helperText={
                    !paymentUserInfo.company_name && 
                    "Nazwa firmy jest wymagana"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="NIP"
                  name="tax_code"
                  value={paymentUserInfo.tax_code}
                  onChange={handleChange}
                  required
                  error={!validateNipCode(paymentUserInfo.tax_code)}
                  helperText={
                    !validateNipCode(paymentUserInfo.tax_code) && 
                    "Błędny NIP: 1234567890"
                  }
                />
              </Grid>
            </>
          )}
        </>
      <Grid item xs={12}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Zapisz i przejdź dalej
        </Button>
      </Grid>
    </Grid>
  );
};

export default PaymentForm;