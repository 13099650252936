import React from "react";
import { Container } from "@mui/material";
import styles from "./Functionalities.module.scss";
import { functions } from "./functionalitiesData";

import table from "../../icons/table.svg";
import FunctionalCategory from "./FunctionalitiesCategory";

const Functionalities = () => {
  return (
    <Container id="functionalities">
      <h2 className={styles.title}>Funkcje naszej aplikacji</h2>
      <div className={styles.functionalities}>
        <div className={styles.container}>
          {functions.map((category, index) => (
            <FunctionalCategory key={index} category={category} index={index} />
          ))}
          <img
            src={table}
            alt="ikona planu lekcji ze ślaczkiem"
            className={styles.graphic}
          />
        </div>
      </div>
    </Container>
  );
};

export default Functionalities;
