import React, { useEffect, useState } from "react";
import styles from "./Arrow.module.scss";
import { ArrowUpFromLine } from "lucide-react";
import { useMediaQuery } from "@react-hook/media-query";

export const Arrow = () => {
  const [arrowVisible, setArrowVisible] = useState(false);
  const isTabletLess = useMediaQuery("(max-width: 900px)");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setArrowVisible(true);
      } else {
        setArrowVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={
        arrowVisible ? `${styles.arrow} ${styles.visible}` : styles.arrow
      }
    >
      <a href="#top">
        {isTabletLess ? (
          <ArrowUpFromLine size={24} color="#3b3b3b" />
        ) : (
          <ArrowUpFromLine size={32} color="#3b3b3b" />
        )}
      </a>
    </div>
  );
};

export default Arrow;
