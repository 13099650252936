import React from "react";

import { Mail, Laugh } from "lucide-react";

import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.content}>
            <h2 className={styles.title}>
              Masz jakieś pytania? <br /> Skontakruj się z nami!
            </h2>
            <div className={styles.contact}>
              <a
                href="mailto: kontakt@easyschedule.pl
"
              >
                <Mail strokeWidth={1} />
                kontakt@easyschedule.pl
              </a>
            </div>
          </div>

          <div className={styles.icon}>
            <Laugh strokeWidth={0.5} size={150} />
          </div>
        </div>
        <div className={styles.legal}>
          <Link
            to="/regulations"
            target="_blank"
            style={{ textDecoration: "underline", color: "lightgrey" }}
          >
            Regulamin aplikacji
          </Link>
          <Link
            to="/privacypolicy"
            target="_blank"
            style={{ textDecoration: "underline", color: "lightgrey" }}
          >
            Polityka Prywatności
          </Link>
          <Link
            to="/sla"
            target="_blank"
            style={{ textDecoration: "underline", color: "lightgrey" }}
          >
            SLA
          </Link>
          <Link
            to="/license"
            target="_blank"
            style={{ textDecoration: "underline", color: "lightgrey" }}
          >
            Umowa licencyjna
          </Link>
        </div>
      </footer>
    </>
  );
};

export default Footer;
