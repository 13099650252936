import React from "react";
import { Stack, Typography, Divider, Button } from "@mui/material";
import PaymentStore from "../../mobx/PaymentStore";
import { toJS } from "mobx";
import {observer} from "mobx-react"

const PaymentResult = observer(({handleNext}) => {
  const { paymentUserInfo, price } = toJS(PaymentStore);

  const submitHandler = async () => {
    const response = await PaymentStore.purchase();
    PaymentStore.setPayment(response.status.Created.url);
    window.open(response.status.Created.url);
    
    if(response.status) {
      handleNext();
    }
  }

  return (
    <Stack display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <Stack gap={3} mb={2}>
        <h2 style={{ textAlign: "center" }}>Podsumowanie</h2>
        <Stack>
          <Typography variant="h3" sx={{ textAlign: "center" }}>
            <span style={{ fontWeight: "bold", color: "tomato" }}>
              {price} zł
            </span>
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="h7" sx={{ textAlign: "center" }}>
            Kupujący
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            <span style={{ fontWeight: "bold" }}>
              {paymentUserInfo.name} {paymentUserInfo.surname}
            </span>
          </Typography>
        </Stack>
        <Stack gap={2}>
          <Divider />

          <Typography variant="h7" sx={{ textAlign: "center" }}>
            Adres
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            <span style={{ fontWeight: "bold" }}>
              {paymentUserInfo.city} {paymentUserInfo.post_code} <br />
              {paymentUserInfo.street} {paymentUserInfo.street_number}
              {paymentUserInfo?.flat_number &&
                `/${paymentUserInfo.flat_number}`}
            </span>
          </Typography>
        </Stack>
        {paymentUserInfo.is_company && (
          <Stack gap={2}>
            <Divider />

            <Typography variant="h7" sx={{ textAlign: "center" }}>
              Firma
            </Typography>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              <span style={{ fontWeight: "bold" }}>
                {paymentUserInfo.company_name}
              </span>
            </Typography>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              <span style={{ fontWeight: "bold" }}>
                {paymentUserInfo.tax_code}
              </span>
            </Typography>
          </Stack>
        )}
        {paymentUserInfo.invoice_needed && !paymentUserInfo.is_company ? (
          <Typography variant='h5' textAlign={'center'}>Faktura na osobę prywatną</Typography>
        ) : paymentUserInfo.is_company ? (
          <Typography variant='h5' textAlign={'center'}>Faktura na firmę</Typography>
        ) : null}
      </Stack>
      <Button onClick={submitHandler} variant="contained" color="success">Przejdź do płatności</Button>
    </Stack>
  );
});

export default PaymentResult;
