import React from "react";
import { Container } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import styles from "./Capabilities.module.scss";
import { capabilities } from "./capabilitiesData";

export const Capabilities = () => {
  return (
    <Container id="capabilities">
      <div className={styles.container}>
        <h2 className={styles.title}>Możliwości Generatora</h2>
        <p className={styles.text}>
          Odkryj szeroki wachlarz funkcji, które pozwolą Ci na precyzyjne
          dostosowanie planu lekcji do potrzeb Twojej szkoły.
          <br />
          Nasza aplikacja oferuje:
        </p>
        <div>
          {capabilities.map((capability, index) => (
            <Capability key={index} capability={capability} />
          ))}
        </div>
      </div>
    </Container>
  );
};

const Capability = ({ capability }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  return (
    <motion.div
      ref={ref}
      className={styles.capability}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      whileHover={{
        scale: 1.01,
        boxShadow: "0px 24px 82px -53px rgba(107, 108, 110, 1)",
        borderRadius: "8px",
      }}
      transition={{ duration: 0.4, ease: "easeOut" }}
    >
      <h3 className={styles.capabilityTitle}>{capability.title}</h3>
      <p className={styles.capabilityDescription}>{capability.description}</p>
    </motion.div>
  );
};
