import React from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import styles from "./Functionalities.module.scss";

const FunctionalCategory = ({ category, index }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      className={styles.category}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      whileHover={{
        scale: 1.05,
        boxShadow: "0px 24px 82px -53px rgba(107, 108, 110, 1)",
        borderRadius: "8px",
      }}
      transition={{ duration: 0.4, ease: "easeOut", delay: index * 0.1 }}
    >
      <div className={styles.icon}>{category.icon}</div>
      <h3>{category.category}</h3>
      <ul>
        {category.functions.map((func, idx) => (
          <li key={idx}>{func}</li>
        ))}
      </ul>
    </motion.div>
  );
};

export default FunctionalCategory;
