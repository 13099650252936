import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import PlansStore from "../../../../../mobx/PlansStore";
import { toJS } from "mobx";
import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LessonPlan from "./LessonPlan/LessonPlan";

const Timetable = () => {
  const { planId } = useParams();
  const plan = toJS(PlansStore.getPlanById(planId));
  const { best_plan: preparedPlans, best_detailed_evaluation: evaluations } =
    plan;
  const { num_of_days: days, num_of_slots_per_day: slots } = preparedPlans;
  const classPlans = preparedPlans?.classes_plans?.sort((a, b) =>
    a.class_name > b.class_name ? 1 : -1
  );
  const teacherPlans = preparedPlans?.teacher_plans?.sort((a, b) =>
    a.teacher_name > b.teacher_name ? 1 : -1
  );
  const classroomPlans = preparedPlans?.classroom_plans?.sort((a, b) =>
    a.classroom_name > b.classroom_name ? 1 : -1
  );
  const [pickedPlan, setPickedPlan] = useState(null);
  const [planType, setPlanType] = useState(null);

  const handlePlanPick = (pickedPlan, planType) => {
    setPickedPlan(pickedPlan);
    setPlanType(planType);
  };

  if (!plan.visible) {
    return (
      <Stack>
        <Typography>
          <Button>
            <NavLink to={-1}>Powrót</NavLink>
          </Button>
        </Typography>
        <Typography variant="h3">
          PLAN NIEDOSTĘPNY W WERSJI DARMOWEJ!
        </Typography>
        <Typography variant="h5" textAlign={"center"}>
          <NavLink to="/purchase">
            Kliknij tutaj aby zakupić pełną wersję aplikacji
          </NavLink>
        </Typography>
      </Stack>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h2" sx={{ mb: 5 }}>
        {pickedPlan !== null ? pickedPlan[`${planType}_name`] : "Wybór planów"}
      </Typography>
      {pickedPlan === null ? (
        <Box
          component="div"
          display={"flex"}
          flexDirection={"row"}
          alignItems={"space-between"}
          justifyContent={"space-evenly"}
        >
          <Stack
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Typography color={"#7692FF"} variant="h4" mb={2}>
              Plany dla klas
            </Typography>
            {classPlans ? (
              classPlans?.map((singleClass) => (
                <h3
                  key={singleClass.class_name}
                  style={{ margin: "3px 0", cursor: "pointer" }}
                  onClick={() => handlePlanPick(singleClass, "class")}
                >
                  {singleClass.class_name}
                </h3>
              ))
            ) : (
              <h3 style={{ margin: "3px 0" }}>Brak planów dla klas</h3>
            )}
          </Stack>
          <Stack
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Typography color={"#7692FF"} variant="h4" mb={2}>
              Plany dla nauczycieli
            </Typography>
            {teacherPlans ? (
              teacherPlans?.map((singleTeacher) => (
                <h3
                  key={singleTeacher.teacher_name}
                  style={{ margin: "3px 0", cursor: "pointer" }}
                  onClick={() => handlePlanPick(singleTeacher, "teacher")}
                >
                  {singleTeacher.teacher_name}
                </h3>
              ))
            ) : (
              <h3 style={{ margin: "3px 0" }}>Brak planów dla nauczycieli</h3>
            )}
          </Stack>
          <Stack
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Typography color={"#7692FF"} variant="h4" mb={2}>
              Plany dla sal lekcyjnych
            </Typography>
            {classroomPlans ? (
              classroomPlans?.map((singleClassroom) => (
                <h3
                  key={singleClassroom.classroom_name}
                  style={{ margin: "3px 0", cursor: "pointer" }}
                  onClick={() => handlePlanPick(singleClassroom, "classroom")}
                >
                  {singleClassroom.classroom_name}
                </h3>
              ))
            ) : (
              <h3 style={{ margin: "3px 0" }}>
                Brak planów dla sal lekcyjnych
              </h3>
            )}
          </Stack>
        </Box>
      ) : (
        <Box component="div" width={"100%"}>
          <Box
            component="div"
            display={"flex"}
            alignItems={"center"}
            gap={2}
            sx={{ marginBottom: "8px" }}
          >
            <Button onClick={() => handlePlanPick(null, null)}>Powrót</Button>
            <Tooltip title="Aby w łatwy sposób przesunąć plan w prawo lub w lewo, użyj kółka myszy, przytrzymując klawisz shift ">
              <IconButton>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <LessonPlan
            choosenPlan={pickedPlan}
            slots={slots}
            days={days}
            planType={planType}
          />
        </Box>
      )}
    </div>
  );
};

export default Timetable;
