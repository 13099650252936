import React from "react";
import { Container, Button, Box } from "@mui/material";
import { paymentData } from "./paymentData";
import styles from "./Payment.module.scss";
import { useNavigate } from "react-router-dom";
import AuthStore from "../../mobx/AuthStore";

const Payment = ({ isPaymentModule = false }) => {
  const navigate = useNavigate();
  const { isUserLogged } = AuthStore;

  const navigateHandler = (flag) => {
    if(isUserLogged && flag === "FREE") {
      navigate("/profile");
      return;
    } else if(isUserLogged && flag === "PAID") {
      navigate("/purchase");
      return;
    }
    if(flag === "FREE") return navigate("/login");
    return navigate('/login/purchase');
  }

  if (isPaymentModule) {
    return (
      <Box className={styles.container} sx={{boxShadow: 2}} width={400}>
        <div className={styles.tile}>
          <h3 className={styles.planTitle}>Wersja {paymentData[1].title}</h3>
          <p className={styles.price}>
            {paymentData[1].price} {paymentData[1].currency}
          </p>
          <p className={styles.description}>{paymentData[1].description}</p>
          <p>Płatność możliwa po wygenerowaniu planu</p>
          Dostęp do aplikacji na 30 dni
          <hr className={styles.divider} />
          <p className={styles.whatYouGet}>Co otrzymujesz:</p>
          <ul className={styles.capabilities}>
            {paymentData[1].capabilities.map((capability, capIndex) => (
              <li key={capIndex} className={styles.capability}>
                {capability}
              </li>
            ))}
          </ul>
        </div>
      </Box>
    );
  }
  return (
    <Container className={styles.payment} id="payment">
      <h2 className={styles.title}>Dostępne plany</h2>

      <div className={styles.container}>
        {paymentData.map((payment, index) => (
          <div
            key={index}
            className={`${styles.tile} ${
              index === 0 ? styles.tileBlack : styles.tileWhite
            }`}
          >
            <h3 className={styles.planTitle}>Wersja {payment.title}</h3>
            <p className={styles.price}>
              {payment.price} {payment.currency}
            </p>
            <p className={styles.description}>{payment.description}</p>
            <Button
              variant="contained"
              className={index === 0 ? styles.buttonWhite : styles.buttonBlue}
              onClick={() => navigateHandler(index === 0 ? "FREE" : "PAID")}
            >
              {index === 0 ? "Wypróbuj za darmo" : "Zakup"}
            </Button>
            <p>
              {index === 0 ? "" : "Płatność możliwa po wygenerowaniu planu"}
            </p>
            <p>{index === 0 ? "" : "Dostęp do aplikacji na 30 dni"}</p>
            <hr className={styles.divider} />
            <p className={styles.whatYouGet}>Co otrzymujesz:</p>
            <ul className={styles.capabilities}>
              {payment.capabilities.map((capability, capIndex) => (
                <li key={capIndex} className={styles.capability}>
                  {capability}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Payment;
