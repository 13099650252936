import React from "react";
import styles from "./LessonPlan.module.scss";
import { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Stack,
} from "@mui/material";
import LessonPlanTile from "./LessonPlanTile/LessonPlanTile";
import { toJS } from "mobx";

import SchoolInfoStore from "../../../../../../mobx/SchoolInfoStore";

const LessonPlan = ({ choosenPlan, slots, days, planType }) => {
  const [plan, setPlan] = useState(null);
  const [lessonNumber, setLessonNumber] = useState([]);
  const schoolConfig = toJS(SchoolInfoStore.schoolPlanConfig.data[0]);

  const generatePlan = () => {
    const planObject = {};

    for (let i = 0; i < days; i++) {
      planObject[i] = [];
    }

    let iterator = 0;
    let currentSlots = slots;

    for (let i = 0; i < days; i++) {
      for (
        let j = iterator;
        j < currentSlots && j < choosenPlan.plan.length;
        j++
      ) {
        if (choosenPlan.plan[j] === null) {
          planObject[i].push([]);
        } else if (!Array.isArray(choosenPlan.plan[j])) {
          planObject[i].push([choosenPlan.plan[j]]);
        } else {
          planObject[i].push(choosenPlan.plan[j]);
        }
        iterator++;
      }

      if (currentSlots <= choosenPlan.plan.length) {
        currentSlots += slots;
      }
    }
    setPlan(planObject);
  };

  useEffect(() => {
    generatePlan();
    for (let i = 0; i < slots; i++) {
      setLessonNumber((prev) => [...prev, i]);
    }
  }, []);

  return (
    <div className={styles.lessonPlan}>
      {plan && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Godziny</TableCell>
                {schoolConfig.daysNames.map((day, index) => (
                  <TableCell key={index} align="center">
                    {day}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {schoolConfig.slotsNames.map((slot, slotIndex) => (
                <TableRow key={slotIndex}>
                  <TableCell align="center">{slot}</TableCell>
                  {Object.keys(plan).map((day, dayIndex) => (
                    <TableCell key={dayIndex}>
                      {plan[day][slotIndex]?.length === 0 ? (
                        <Stack minWidth={"400px"}>
                          <LessonPlanTile color="lightgrey" planType="none" />
                        </Stack>
                      ) : (
                        <Stack display={"flex"} flexDirection={"row"} gap={1}>
                          {plan[day][slotIndex]?.map((l) => (
                            <LessonPlanTile
                              key={l.id}
                              color="#C5E0FF90"
                              lessonInfo={l}
                              planType={planType}
                            />
                          ))}
                        </Stack>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default LessonPlan;
