import React, { useState, useEffect } from "react";
import {
  FormControl,
  Stack,
  Typography,
  Slider,
  Button,
} from "@mui/material";
import { toJS } from "mobx";
import SchoolInfoStore from "../../../../../mobx/SchoolInfoStore";

const LDTypeForm = ({ type, handleChanges }) => {
  const { numOfDays: days, numOfSlotsPerDay: slots } = toJS(
    SchoolInfoStore?.schoolPlanConfig?.data?.[0]
  );

  const hoursArrayGenerator = () => {
    if (type === "SPECIFIC") {
      return Array.from({ length: days }, (_, i) => i + 1).reduce(
        (acc, day) => {
          acc[day] = 1;
          return acc;
        },
        {}
      );
    } else if (type === "RANGE") {
      return Array.from({ length: days }, (_, i) => i + 1).reduce(
        (acc, day) => {
          acc[day] = [1, slots];
          return acc;
        },
        {}
      );
    } else return [];
  };

  const [selectedDays, setSelectedDays] = useState(hoursArrayGenerator());

  useEffect(() => {
    setSelectedDays([]);
    setSelectedDays(hoursArrayGenerator());
  }, [type, days, slots]);

  const handleSelectedDaysChange = (e, day) => {
    const newSlots = e.target.value;
    setSelectedDays({
      ...selectedDays,
      [day]: newSlots,
    });
  };

  const handleRangeDaysChange = (e, day) => {
    const newSlots = e.target.value;
    console.log(newSlots);
    setSelectedDays({
      ...selectedDays,
      [day]: newSlots,
    });
  };

  const generateCorrentTypeForm = () => {
    if (type === "EVEN") {
      return null;
    } else if (type === "SPECIFIC") {
      return (
        <Stack gap={2}>
          <Typography variant="h6">
            Ustaw określoną dystrybucję lekcji:
          </Typography>
          <Stack
            display="flex"
            flexDirection={"row"}
            justifyContent={"space-between"}
            maxWidth={550}
            flexWrap={"wrap"}
            gap={2}
          >
            {Array.from({ length: days }, (_, i) => i + 1).map((day) => (
              <Stack key={`day-${day}`} gap={1}>
                <Typography variant="h6">Dzień {day}</Typography>

                <FormControl>
                  <Stack width={200}>
                    <Slider
                      value={selectedDays[day] || 1}
                      onChange={(e) => handleSelectedDaysChange(e, day)}
                      step={1}
                      marks
                      min={1}
                      max={slots}
                      valueLabelDisplay="auto"
                    />
                  </Stack>
                </FormControl>
              </Stack>
            ))}
          </Stack>
          <Button onClick={() => handleChanges(selectedDays)}>Zaakceptuj zmiany</Button>
        </Stack>
      );
    } else if (type === "RANGE") {
      return (
        <Stack gap={2}>
          <Typography variant="h6">Ustaw zakres godzinowy dla dni: </Typography>
          <Stack
            display="flex"
            flexDirection={"row"}
            justifyContent={"space-between"}
            maxWidth={550}
            flexWrap={"wrap"}
            gap={2}
          >
            {Array.from({ length: days }, (_, i) => i + 1).map((day) => (
              <Stack key={`day-${day}`} gap={1} width={200}>
                <Typography variant="h6">Dzień {day}</Typography>
                <Stack>
                  <FormControl>
                    <Stack>
                      <Slider
                        value={selectedDays[day] || [1, slots]}
                        onChange={(e) => handleRangeDaysChange(e, day)}
                        step={1}
                        marks
                        min={1}
                        max={slots}
                        valueLabelDisplay="auto"
                      />
                    </Stack>
                  </FormControl>
                </Stack>
              </Stack>
            ))}
          </Stack>
          <Button onClick={() => handleChanges(selectedDays)}>Zaakceptuj zmiany</Button>
        </Stack>
      );
    }
  };
  return generateCorrentTypeForm();
};

export default LDTypeForm;
