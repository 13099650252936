import React, { useEffect } from "react";

import Navigation from "../../components/Navigation/Navigation";
import Banner from "../../components/Banner/Banner";
import Description from "../../components/Description/Description";
import Funktionalities from "../../components/Functionalities/Functionalities";
import Footer from "../../components/Footer/Footer";
import { Arrow } from "../../components/Arrow/Arrow";
import Payment from "../../components/Payment/Payment";
import { Capabilities } from "../../components/Capabilities/Capabilities";

import styles from "./MainPage.module.scss";

const MainPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.main}>
      <Navigation />
      <Banner />
      <Description />
      <Funktionalities />
      <Capabilities />
      <Payment />
      <Footer />
      <Arrow />
    </div>
  );
};

export default MainPage;
