import React from "react";
import { Container } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import styles from "./Description.module.scss";
import { steps } from "./descriptionData";

const Description = () => {
  return (
    <Container id="description">
      <div className={styles.description}>
        <div className={styles.text}>
          <h2>Opis produktu</h2>
          <p>
            <strong>Jak to działa?</strong>
            <br />
            Nasza aplikacja umożliwia łatwe tworzenie harmonogramów lekcji
            dostosowanych do indywidualnych potrzeb każdej szkoły. Wprowadź dane
            szkoły, wybierz wymagania i wygeneruj harmonogram w kilka minut!
          </p>

          <div className={styles.steps}>
            <strong>Kroki do stworzenia harmonogramu</strong>
            <div>
              {steps.map((step, index) => (
                <Step key={index} step={step} />
              ))}
            </div>
          </div>

          <div className={styles.image}>
            <img
              src="assets/images/Aesthetic Plan Lekcji Do Druku 1.jpg"
              alt="obrazek Plan lekcji"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

const Step = ({ step }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      className={styles.step}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.4, ease: "easeOut" }}
    >
      <strong>{step.step}</strong>
      <p>{step.description}</p>
    </motion.div>
  );
};

export default Description;
