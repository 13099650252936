import React from "react";
import styles from "../style.module.scss";
import PlansStore from "../../../mobx/PlansStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import LoadingBar from "../../LoadingBar/LoadingBar";
import Plans from "./Plans/Plans";
import { Routes, Route } from "react-router-dom";
import Timetable from "./Plans/Timetable/Timetable";
import { useEffect } from "react";

const Timetables = observer(() => {
  const { plans } = toJS(PlansStore);

  useEffect(() => {
    PlansStore.getPlans();
  }, []);

  if (plans.loading) return <LoadingBar />;
  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className={styles.timetables}>
            <h2>Plany lekcji</h2>
            <Plans plans={plans} />
          </div>
        }
      />
      <Route path="/:planId" element={<Timetable />} />
    </Routes>
  );
});

export default Timetables;
