export const paymentData = [
  {
    title: "Darmowa",
    price: 0,
    currency: "zł",
    description: "Podstawowy plan z ograniczoną funkcjonalnością.",
    capabilities: [
      "Podział klas dla grupy",
      "Konfigurowanie rozkładu lekcji",
      "Blokowanie lekcji w tym samym czasie",
      "Minimalizacja okienek nauczycieli",
      "Likwidacja okienek w planach uczniów",
      "Ilość klas: 10",
      "Ilość wygenerowanych planów w miesiącu: 5",
    ],
  },
  {
    title: "Pełna",
    price: 130,
    currency: "zł",
    description: "Pełny plan z nieograniczonymi możliwościami.",
    capabilities: [
      "To co w planie darmowym",
      "Preferencje",
      "Wybór momentu rozpoczęcia lekcji",
      "Kontrola ilości trudnych lekcji w ciągu dnia",
      "Wybór lekcji skrajnych",
      "Blokowanie lekcji w tym samym dniu",
      "Ilość klas: nieograniczona",
      "Ilość wygenerowanych planów: nieograniczona",
    ],
  },
];
