import { observable, action, computed, makeAutoObservable } from "mobx";
import DataService from "../services/DataService";

class PaymentStore {
  @observable paymentUserInfo = undefined;
  @observable price = null;
  @observable paymentLink = null;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setPaymentLink(link) {
    this.paymentLink = link;
  }

  @action
  setPaymentUserInfo(userInfo) {
    this.paymentUserInfo = userInfo;
  }

  @action
  setPrice(price) {
    this.price = price;
  }

  setPayment(link) {
    this.setPaymentLink(link);
  };

  purchase = async () => {
    const data = 
      this.paymentUserInfo
      // price: this.price,
    ;

    const response = await DataService.purchase(data);
    return response;
  };
}

export default new PaymentStore();
