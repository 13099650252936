import React, { useEffect, useState } from "react";
import { toJS } from "mobx";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Stack,
} from "@mui/material";
import AddLesson from "./AddLesson";
import ModalComponent from "../../../Modal/ModalComponent";
import { observer } from "mobx-react";
import SingleRow from "./SingleRow/SingleRow";
import SchoolInfoStore from "../../../../mobx/SchoolInfoStore";

const SingleClassLessons = observer(({ singleClass }) => {
  const { lessons, subjects } = SchoolInfoStore;
  const [classLessons, setClassLessons] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [lessonIdToCopy, setLessonIdToCopy] = useState();
  const handleClose = () => setIsOpen(false);

  const getClassLessons = (classId) => {
    const lessonsData = lessons.data;
    const classLessons = lessonsData.filter(
      (lesson) => lesson.student_class_id?.$oid === classId
    );
    // .sort((a, b) => {
    //   // Sort by lessonType.name
    //   const lessonTypeComparison = (a.lessonType?.name || "").localeCompare(
    //     b.lessonType?.name || ""
    //   );
    //   if (lessonTypeComparison !== 0) return lessonTypeComparison;

    //   // Sort by teacher.name
    //   const teacherComparison = (a.teacher?.name || "").localeCompare(
    //     b.teacher?.name || ""
    //   );
    //   if (teacherComparison !== 0) return teacherComparison;

    //   // Sort by lessonLabel.name
    //   return (a.lessonLabel?.name || "").localeCompare(
    //     b.lessonLabel?.name || ""
    //   );
    // });

    setClassLessons(classLessons);
    return classLessons;
  };

  const handleAdd = async (subject) => {
    const data = {
      lesson_type_id: subject._id,
      size: 1,
      amountPerWeek: 1,
      teacher_id: null,
      lesson_label_id: null,
      allowedClassrooms: [],
      groups: toJS(singleClass.groups).map((group) => group._id),
      student_class_id: singleClass._id,
    };

    const dataForMobx = {
      ...data,
      lessonType: subject,
      teacher: null,
      lessonLabel: null,
      allowedClassroomsDetails: [],
      groupDetails: toJS(singleClass.groups),
    };

    void SchoolInfoStore.addLesson(data, dataForMobx);
  };

  useEffect(() => {
    getClassLessons(singleClass._id.$oid);

    return () => {};
  }, [lessons.data, lessons.loading]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Lp.</TableCell>
            <TableCell>Przedmiot</TableCell>
            <TableCell>Rozmiar lekcji</TableCell>
            <TableCell>Ilość lekcji w tyg.</TableCell>
            <TableCell>Nauczyciel</TableCell>
            <TableCell>Etykieta</TableCell>
            <TableCell>Grupy</TableCell>
            <TableCell>Dozwolone sale</TableCell>
            <TableCell>Akcja</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {classLessons.map((classLesson, index) => (
            <SingleRow
              classLesson={classLesson}
              index={index}
              key={`singleClassLesson-${classLesson._id.$oid}`}
              singleClass={toJS(singleClass)}
              lessonIdToCopy={lessonIdToCopy}
              setLessonIdToCopy={setLessonIdToCopy}
            />
          ))}
        </TableBody>
      </Table>
      {isOpen && (
        <ModalComponent isOpen={isOpen} handleClose={handleClose}>
          <AddLesson singleClass={singleClass} handleClose={handleClose} />
        </ModalComponent>
      )}
      <Stack flexDirection="row" alignItems="center">
        <Button
          variant="contained"
          onClick={() => setIsOpen(true)}
          style={{ margin: "10px" }}
        >
          Dodaj lekcję
        </Button>
        <FormControl sx={{ width: 250 }} size="small">
          <InputLabel id="subject">Przedmiot</InputLabel>
          <Select
            labelId="subject"
            id="subject"
            label="Przedmiot"
            onChange={(e) => {
              const selectedSubject = subjects.data.find(
                (sub) => sub._id.$oid === e.target.value
              );
              void handleAdd(selectedSubject);
            }}
            size="small"
            defaultValue=""
          >
            {subjects.data.map((subject) => (
              <MenuItem key={subject._id.$oid} value={subject._id.$oid}>
                {subject.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </TableContainer>
  );
});

export default SingleClassLessons;
