import React from "react";
import styles from "./styles.module.scss";
import {
  Container,
  Button,
  TextField,
  Box,
  Stack,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import AuthStore from "../../mobx/AuthStore";
import CustomAlert from "../Alert/CustomAlert";
import { useNavigate } from "react-router-dom";
import Logo from "../../icons/LogoES.svg";
import { useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const Login = () => {
  const navigate = useNavigate();
  const [saveLoginData, setSaveLoginData] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const { id } = useParams();

  const handleSaveLoginData = () => {
    setSaveLoginData(!saveLoginData);
  };

  const handleLogin = async () => {
    setError(false);

    const loginData = {
      email,
      password,
    };

    const response = await AuthStore.login(loginData);
    if (response.error) {
      setError(true);
      return;
    }

    if (id) {
      navigate(`/purchase`);
      return;
    }
    navigate("/profile");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin();
    }
  };

  return (
    <>
      <Helmet>
        <title>Timetable Planner for schools - login</title>
        <meta
          name="description"
          content="Zaloguj się do Generatora planów lekcji dla szkół - Efektywne tworzenie i zarządzanie planami lekcji z naszym prostym w użyciu narzędziem."
        />
      </Helmet>
      <Container>
        <div className={styles.auth}>
          <div>
            <NavLink to="/">
              <img
                src={Logo}
                alt="logo Easy schedule"
                style={{
                  width: "200px",
                  marginTop: "20px",
                }}
              />
            </NavLink>
          </div>
          <div className={styles.form}>
            <div className={styles.formWrapper}>
              <Stack mb={2}>
                <Typography variant="h3" fontWeight={300} textAlign={"center"}>
                  Logowanie
                </Typography>
                <Typography
                  variant="body1"
                  textAlign={"center"}
                  fontWeight={300}
                  p={2}
                >
                  Witaj ponownie!
                </Typography>
                {error && (
                  <CustomAlert
                    status="error"
                    message="Niepoprawne dane logowania"
                  />
                )}
              </Stack>

              <Box component="form" onKeyDown={handleKeyDown}>
                <Stack gap={2} mb={2}>
                  <TextField
                    label="Email"
                    id="email"
                    placeholder="jankowalski@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                  />
                  <TextField
                    label="Hasło"
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                  />
                </Stack>
                <Typography variant="body1" fontWeight={300} pb={2}>
                  <NavLink to="/reset">Zapomniałem hasła</NavLink>
                </Typography>
                <Button variant="contained" onClick={handleLogin}>
                  Zaloguj się
                </Button>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={saveLoginData}
                    onChange={handleSaveLoginData}
                  />
                }
                label="Zapamiętaj mnie"
              />
              <p>
                Nie masz jeszcze konta?{" "}
                <NavLink to="/register">Zarejestruj się</NavLink>.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Login;
