import PreferencesForm from "./RequirementForms/PreferencesForm/PreferencesForm";
import BlockingLesson from "./RequirementForms/BlockingLessonATST/BlockingLesson";
import EdgeLessonForm from "./RequirementForms/EdgeLessonForm/EdgeLessonForm";
import LessonDistributionForm from "./RequirementForms/LessonDistributionForm/LessonDistributionForm";
import LabeledLessonsPerDayForm from "./RequirementForms/LabeledLessonsPerDayForm/LabeledLessonsPerDayForm";
import LessonStartForm from "./RequirementForms/LessonStartForm/LessonStartForm";

export const typesList = [
  {
    id: 1,
    type: "PREFERENCE",
    polishtype: "Preferencje",
  },
  {
    id: 2,
    type: "BLOCKING_LESSON_AT_THE_SAME_TIME",
    polishtype: "Blokowanie zajęć w tym samym czasie",
  },
  {
    id: 3,
    type: "FORBIDDEN_LESSON_ON_THE_SAME_DAY",
    polishtype: "Zabronione zajęcia w tym samym dniu",
  },
  {
    id: 4,
    type: "EDGE_LESSON",
    polishtype: "Zajęcia na krawędziach",
  },
  {
    id: 5,
    type: "LESSON_DISTRIBUTION",
    polishtype: "Rozkład zajęć",
  },
  {
    id: 6,
    type: "LABELED_LESSONS_PER_DAY",
    polishtype: "Zaetykietowane zajęcia w danym dniu",
  },
  {
    id: 7,
    type: "LESSON_START",
    polishtype: "Początek zajęć",
  }
];

export const generateCorrectForm = (requirementType) => {
  switch (requirementType) {
    case "PREFERENCE":
      return <PreferencesForm />;
    case "BLOCKING_LESSON_AT_THE_SAME_TIME":
      return <BlockingLesson isBlockingLessonAtTheSameTime={true}/>;
    case "FORBIDDEN_LESSON_ON_THE_SAME_DAY":
      return <BlockingLesson isBlockingLessonAtTheSameTime={false}/>;
    case "EDGE_LESSON":
      return <EdgeLessonForm />;
    case "LESSON_DISTRIBUTION":
      return <LessonDistributionForm />;
    case "LABELED_LESSONS_PER_DAY":
      return <LabeledLessonsPerDayForm />;
    case "LESSON_START":
      return <LessonStartForm />;
    default:
      return null;
  }
};

export const preferencesTypeList = [
  {
    id: 1,
    type: "TEACHER",
    polishtype: "Nauczyciel",
  },
  {
    id: 2,
    type: "CLASSROOM",
    polishtype: "Sala",
  },
  {
    id: 3,
    type: "GROUP",
    polishtype: "Grupa",
  }
];

export const blockingLessonsTypeList = [
  {
    id: 1,
    type: "LESSON_TYPE",
    polishtype: "Przedmiot"
  },
  {
    id: 2,
    type: "LESSON_LABEL",
    polishtype: "Etykieta"
  },
  {
    id: 3,
    type: "UNIQUE_ID",
    polishtype: "Lekcja"
  }
];

export const edgeTypes = [
  {
    id: 1,
    type: "FIRST",
    polishtype: "Pierwsza krawędź"
  },
  {
    id: 2,
    type: "LAST",
    polishtype: "Ostatnia krawędź"
  },
  {
    id: 3,
    type: "BOTH",
    polishtype: "Obie krawędzie"
  }
];

export const distributionTypes = [
  {
    id: 1,
    type: "EVEN",
    polishtype: "Równomiernie"
  },
  {
    id: 2,
    type: "SPECIFIC",
    polishtype: "Specyficznie"
  },
  {
    id: 3,
    type: "RANGE",
    polishtype: "Zakres"
  }
]

export const formListConfig = {
  ITEM_HEIGHT: 48,
  ITEM_PADDING_TOP: 8,
  MENU_PROPS: {
    PaperProps: {
      style: {
        get maxHeight() {
          return formListConfig.ITEM_HEIGHT * 4.5 + formListConfig.ITEM_PADDING_TOP;
        },
        width: 250,
      },
    },
  }
}