import "./App.scss";
import Root from "./Root";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <div className="App">
      <HelmetProvider>
        <BrowserRouter>
          <Root />
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
