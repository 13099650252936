import React from "react";
import styles from "./Banner.module.scss";
import { Button } from "@mui/material";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import AuthStore from "../../mobx/AuthStore";

const Banner = () => {
  const { isUserLogged } = AuthStore;
  return (
    <section id="banner" className={styles.banner}>
      <motion.div
        className={styles.image}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
      >
        <img src="assets/images/banner1.jpg" alt="banner" />
      </motion.div>
      <motion.div
        className={styles.content}
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, delay: 0.3 }}
      >
        <div className={styles.text}>
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          >
            Kreator planu lekcji
          </motion.h1>
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.9 }}
          >
            Usprawnij tworzenie planu lekcji dzięki naszemu intuicyjnemu
            narzędziu, które pozwala szybko projektować harmonogramy zajęć.
          </motion.h2>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 1.2 }}
          >
            <NavLink to={isUserLogged ? "/profile" : "/login"}>
              <Button
                variant="contained"
                color="success"
                sx={{ padding: "15px 25px" }}
              >
                Wypróbuj za darmo
              </Button>
            </NavLink>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

export default Banner;
