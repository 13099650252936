import React, { useState } from "react";
import {
  Stack,
  Typography,
  Box,
  Stepper,
  StepLabel,
  Step,
  Button,
} from "@mui/material";
import { steps } from "./paymentModule.config";
import PlanChoose from "./PlanChoose";
import PaymentForm from "./PaymentForm";
import PaymentResult from "./PaymentResult";
import PaymentStore from "../../mobx/PaymentStore";
import logo from "../../icons/LogoES.svg";
import { useNavigate, NavLink } from "react-router-dom";
import { toJS } from "mobx";
import { observer } from "mobx-react";

const PaymentModule = observer(() => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const { paymentLink } = toJS(PaymentStore);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;

    if (activeStep === 0) {
      PaymentStore.setPrice(130);
    }

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    navigate("/");
  };

  return (
    <Stack
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100vh"}
      id="paymentModule"
    >
      <Stack>
        <img src={logo} alt="easyschedule-logo" style={{ width: "300px" }} />
      </Stack>
      <Box sx={{ width: "80%", minHeight: "60vh" }} mt={5}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label.name} {...stepProps}>
                <StepLabel {...labelProps}>{label.name}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "column", pt: 2 }}>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pt: 2,
                  width: "100%",
                }}
              >
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Wróć na stronę główną</Button>
              </Stack>
              <Typography mt={2} mb={1} textAlign={"center"} variant="h3">
                Dziękujemy za zaufanie!
              </Typography>
              <Typography textAlign={"center"}>
                Dokończ płatność nowym oknie. Jeżeli okno nie otworzyło się
                automatycznie, kliknij{" "}
                <span style={{ color: "blue", cursor: "pointer" }}>
                  <a href={`${paymentLink}`} target="_blank" rel="noreferrer">tutaj</a>
                </span>
                .
              </Typography>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Wróć
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />

              {activeStep === steps.length - 1 ? null : activeStep ===
                1 ? null : (
                <Button onClick={handleNext}>Dalej</Button>
              )}
            </Box>
          </React.Fragment>
        )}
        {activeStep === 0 && <PlanChoose />}
        {activeStep === 1 && <PaymentForm handleNext={handleNext} />}
        {activeStep === 2 && <PaymentResult handleNext={handleNext} />}
      </Box>
    </Stack>
  );
});

export default PaymentModule;
