import React from "react";
import Payment from "../../components/Payment/Payment";
import { Stack } from "@mui/material";

const PlanChoose = () => {
  return (
    <Stack display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <Payment isPaymentModule={true} />
    </Stack>
  );
};

export default PlanChoose;
