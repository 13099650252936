export const steps = [
  {
    name: "Wybierz plan",
  },
  {
    name: "Dane kontrahenta",
  },
  {
    name: "Podsumowanie",
  },
];

export const paymentFormErrorHandler = (user) => {
  if(user.name.length < 1) {
    return false;
  }
  if(user.surname.length < 1) {
    return false;
  }
  if(user.invoice_needed && !user.street) {
    return  false;
  }
  if(user.invoice_needed && !user.street_number) {
    return false;
  }
  if(user.invoice_needed && !user.post_code) {
    return false;
  }
  if(user.invoice_needed && !user.city) {
    return;
  }
  if(user.is_company && !user.company_name) {
    return false;
  }
  if(user.is_company && !user.tax_code) {
    return false;
  }

  return true;
  
}

export const validatePostalCode = postalCode => {
  const postalCodePattern = /^\d{2}-\d{3}$/
  return postalCodePattern.test(postalCode);
}

export const validateNipCode = nipCode => {
  const nipCodePattern = /^[0-9]{10}$/
  return nipCodePattern.test(nipCode);
}